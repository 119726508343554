import { FormError } from '@/components/forms/FormErrors'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import { useQueryParams } from '@/hooks/useQueryParams'
import { EnvelopeIcon } from '@heroicons/react/24/outline'
import { ReactNode, useEffect, useState } from 'react'
import { AccountQueryParams } from '.'
import { signup } from './actions'

export const SignupForm = ({
  submitLabel,
  onCompleted,
}: {
  submitLabel: ReactNode
  onCompleted: (email: string) => void
}) => {
  const { setQueryParams } = useQueryParams<AccountQueryParams>()
  const [error, setError] = useState('')
  const [valid, setValid] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(
    () => () => {
      setLoading(false)
      setValid(false)
    },
    [],
  )

  const handleSubmit = async (formData: FormData) => {
    const email = formData.get('email') as string
    const signupResp = await signup(email)

    if (signupResp.error) {
      setLoading(false)
      setError(signupResp.error)
      return
    }

    onCompleted(email)
    setQueryParams({ step: 'verify' })
  }

  return (
    <>
      <form className='space-y-6' action={handleSubmit} onSubmit={() => setLoading(true)}>
        <FormError error={error} />

        <div>
          <label htmlFor='email' className='block text-sm font-medium leading-6 text-gray-900'>
            Email address
          </label>
          <div className='mt-2'>
            <input
              onChange={(e) => setValid(!!e.target.value.match(/^[^@]+@[^@]+\.[^@]+$/))}
              id='email'
              name='email'
              type='email'
              autoComplete='email'
              required
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            />
          </div>
        </div>

        <div>
          <button
            disabled={!valid || loading}
            type='submit'
            className='inline-flex w-full gap-x-1.5 items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-400'
          >
            {loading ? <LoadingSpinner /> : submitLabel}
          </button>
        </div>
      </form>

      <div>
        <div className='relative mt-10'>
          <div className='absolute inset-0 flex items-center' aria-hidden='true'>
            <div className='w-full border-t border-gray-200' />
          </div>
          <div className='relative flex justify-center text-sm font-medium leading-6'>
            <span className='bg-white px-6 text-gray-900'>Or continue with</span>
          </div>
        </div>

        <div className='mt-6'>
          <a
            href='#'
            className='inline-flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent'
          >
            <svg className='h-5 w-5' aria-hidden='true' viewBox='0 0 24 24'>
              <path
                d='M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z'
                fill='#EA4335'
              />
              <path
                d='M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z'
                fill='#4285F4'
              />
              <path
                d='M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z'
                fill='#FBBC05'
              />
              <path
                d='M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z'
                fill='#34A853'
              />
            </svg>
            <span className='text-sm font-semibold leading-6'>Google</span>
          </a>
        </div>
      </div>
    </>
  )
}
