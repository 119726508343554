'use client'

import { useQueryParams } from '@/hooks/useQueryParams'
import { ReactNode, useState } from 'react'
import { AccountQueryParams } from '.'
import { SignupForm } from './SignupForm'
import { VerifyForm } from './VerifyForm'

export const AccountForm = ({ submitLabel }: { submitLabel: ReactNode }) => {
  const [email, setEmail] = useState('')
  const { queryParams, setQueryParams } = useQueryParams<AccountQueryParams>()

  return queryParams.get('step') === 'verify' ? (
    <VerifyForm email={email} />
  ) : (
    <SignupForm submitLabel={submitLabel} onCompleted={setEmail} />
  )
}
