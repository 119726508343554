import { FormError } from '@/components/forms/FormErrors'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import { useQueryParams } from '@/hooks/useQueryParams'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useRef, useState } from 'react'
import PinInput from 'react-pin-input'
import { AccountQueryParams } from '.'
import { verify } from './actions'

export const VerifyForm = ({ email }: { email: string }) => {
  const { queryParams, setQueryParams } = useQueryParams<AccountQueryParams>()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [otp, setOtp] = useState('')
  const pinInputRef = useRef<PinInput>(null)

  const verifyOtp = async (otp: string) => {
    setLoading(true)
    const verifyOtpResp = await verify(email, otp)

    if (verifyOtpResp?.error) {
      setLoading(false)
      setError(verifyOtpResp.error)
      return
    }
  }

  return (
    <div className='space-y-8'>
      <div>
        <h2 className='text-2xl font-bold leading-9 tracking-tight text-gray-900'>Verify your email</h2>
        <p className='mt-2 text-sm leading-6 text-gray-500'>Enter the verification code sent to {email}</p>
      </div>

      <FormError error={error} />

      <form
        className='flex flex-col items-center py-4 w-fit space-y-4 justify-center mx-auto'
        onSubmit={(e) => {
          e.preventDefault()
          verifyOtp(otp)
        }}
      >
        <PinInput
          ref={pinInputRef}
          disabled={loading}
          length={6}
          initialValue=''
          onChange={(value) => setOtp(value)}
          type='numeric'
          inputMode='number'
          onComplete={(value) => verifyOtp(value)}
          autoSelect
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          inputStyle={{ border: undefined }}
          inputFocusStyle={{
            border: undefined,
            outline: undefined,
            boxShadow: undefined,
          }}
        />

        <button
          disabled={loading || otp.length < 6}
          type='submit'
          className='inline-flex w-full gap-x-1.5 items-center justify-center rounded-md disabled:bg-gray-400 bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        >
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              Complete sign up
              <CheckIcon className='-ml-0.5 h-5 w-5' aria-hidden='true' />
            </>
          )}
        </button>
      </form>

      <p className='text-center text-sm text-gray-500'>
        Haven&apos;t received the email?{' '}
        <button
          onClick={() => setQueryParams({ step: 'resend' })}
          className='font-semibold leading-6 text-indigo-600 hover:text-indigo-500'
        >
          Resend code
        </button>
      </p>
    </div>
  )
}
